<template>
  <div>
    <MasterHeader />
    <Loader :times="2" v-if="isLoading"></Loader>
    <div class="" v-else>
    <!-- <sign-up-modal v-if="hasNewSignupFlow"/> -->
    <div class="container mb-4">
      <div class="row d-flex justify-content-md-center">
        <div class="col-12 text-md-center mb-4">
          <h4 class="col-6 mx-auto">
            Find the right thesis topic with the right group of students
          </h4>
        </div>
        <!-- <div class="col-12 col-md-3 align-self-center">
          <label class="color-third" for="filterSemester"
            >Field of interest</label
          >
          <multiselect v-model="user.interests" :options="jobsData" :showLabels="false" group-values="subjobs" group-label="parent" placeholder="Type to search" track-by="name" label="name"><span slot="noResult">Oops! No elements found. Consider changing the search query.</span></multiselect>
        </div> -->
        <div class="col-12 col-md-3 align-self-center">
          <label class="color-third" for="filterSemester"
            >Collaboration semester</label
          >
          <select
            name=""
            class="form-control"
            id="filterSemester"
            v-model="filterSemester"
          >
            <option value="All">All</option>
            <option v-for="(semester, index) in filters.semesters" :key="index">
              {{ semester }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-3 align-self-center">
          <label class="color-third" for="filterDegree">Degree</label>
          <select class="form-control" id="filterDegree" v-model="filterDegree">
            <option value="All">All</option>
            <option v-for="(degree, index) in filters.degrees" :key="index">
              {{ degree }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="container container-collaborations">
      <div class="collaborations">
        <div class="card-columns" v-if="filteredList">
          <!--  -->
          <router-link
            :to="'/collaborations/' + collab._id.$oid"
            class="card"
            v-for="(collab, index) in filteredList"
            :key="index"
          >
            <div class="card-image">
              <img
                v-if="collab.institution"
                :src="
                  '/assets/institutions/' +
                  collab.institution.id +
                  '.png'
                "
                class="card-img-top"
                :title="collab.institution.name"
              />
            </div>
            <div class="card-body">
              <div class="card-area">
                <small>{{ cutText(collab.area, 60) }}</small>
              </div>
              <hr />
              <h2 class="card-title">
                {{ cutText(collab.title, 100) }}
              </h2>
              <h6 class="card-subtitle">{{ collab.subtitle }}</h6>
              <ul
                v-if="collab.keywords"
                class="card-keywords pt-2"
              >
                <li
                  class="keyword"
                  v-for="(keyword, index) in collab.keywords"
                  :key="index"
                >
                  {{ keyword }}
                </li>
              </ul>
              <p class="card-text">
                {{ cutText(htmlToText(collab.content), 300) }}
              </p>
              <div class="card-bottom mt-4">
                <span>{{ collab.degree }}</span
                ><span class="divider">|</span>
                <span>{{ collab.targetSemester }}</span
                ><span class="divider">|</span>
                {{ collab.members.length }} members
                <!-- <span class="badge" v-if="collab.collaboration.members.includes(user.userId)">yours</span> -->
                <span class="btn float-right">View</span>
              </div>
            </div>
          </router-link>
        </div>
        <div v-else>
          <h4 class="text-center color-third mt-5">
            Sorry! - But we didn't match any collaborations to your filters. Try
            adjusting your filters.
          </h4>
        </div>
      </div>
    </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import MasterHeader from '@/components/MasterHeader.vue';
import apiInstance from '../../axios/axios';
import SignUpModal from '../../components/signUpModal.vue';
import Loader from '../../components/Loader.vue';
import { base64ToString } from '../../utils/utils';

export default {
  components: {
    MasterHeader,
    Loader
},
  data() {
    return {
      filterDegree: 'All',
      filterSemester: 'All',
      jobsData: false,
    };
  },
  metaInfo: {
      meta:[
        {
          name: 'robots',
          content: 'noindex, nofollow', // Adding noindex and nofollow directives
        },
      ]
  },
    created() {
    // const userType = localStorage.getItem('i_am')
    // const userVerified = localStorage.getItem('u-verified')
        let userType = localStorage.getItem('i_am')
    userType = base64ToString(userType)
        let userVerified = localStorage.getItem('u-verified')
    if (userVerified == 0 && userType == 'student') {
      this.$router.push('/user/student-welcome')
    }
    if (userVerified == 0 && userType == 'professional') {
      this.$router.push('/user/welcome')
    }
  },
  mounted() {
    // await apiInstance.get('collaborations')
    // console.log(this.collaboration, 'dd')
    // if(!this.collaboration) {
    this.$store.dispatch('resetCollaborationLoadingState');
    if (!this.$store.getters.allCollaborations || this.$store.getters.allCollaborations.length === 0) {
      this.$store.dispatch('getAllCollaborations');
    }
    // todo wait for sumit to fix the api response here as well
    // }
    // this.loadJobs();
    // console.log("mounted")
  },

  computed: {
    // ...mapState(['collaboration']),
    isLoading(){
      return this.$store.getters.collaborationIsLoading
  },
    //     hasNewSignupFlow(){
    //   const vm = this
    //   const isValid = vm.$store.getters.currentProgress >= 0 ? true : false
    //   const isStudent = vm.$store.getters.role === 'Student'
    //     if (!isValid && isStudent) {
    //       localStorage.setItem('notSignedUp', 1);
    //     }
    //     if (isValid) {
    //       localStorage.removeItem('notSignedUp');
    //     }
    //   return true
    // },  
    filters: function () {
      var vm = this;
      var result = { degrees: [], semesters: [] };
      var collection = this.$store.getters.allCollaborations;
      if (collection && collection.length > 0) {
        collection.forEach((item) => {
          //fill degress
          if (!result.degrees.includes(item.degree)) {
            if(item.degree){
              result.degrees.push(item.degree);
            }
          }
          //fill semesters
          if (!result.semesters.includes(item.targetSemester)) {
            result.semesters.push(item.targetSemester);
          }
        });

        result.degrees.sort();
        result.semesters.sort();
      }
      // this.$forceUpdate()
      return result;
    },
    filteredList: function () {
      var vm = this;
      var collaborationFilter = vm.proposalFilter;
      var collection = this.$store.getters.allCollaborations;
      var result = collection;
      // console.log("🚀 ~ file: Collaborations.vue:185 ~ collection:", collection)

      if (vm.filterDegree !== 'All') {
        result = collection.filter((item) => {
          return item.degree === vm.filterDegree;
        });
      }

      if (vm.filterSemester !== 'All') {
        result = result.filter((item) => {
          return item.targetSemester === vm.filterSemester;
        });
      }
      // this.$forceUpdate()
      return result;
    },
    // jobListLink: () =>  {
    //         return storage.refFromURL('gs://excelerate-stage.appspot.com/Interestfields.json').getDownloadURL()
    //     },
  },
  methods: {
    //  async loadJobs(){
    //   //  console.log("loadjobs",jobListLink )
    //   let jobListLink = await this.jobListLink;
    //   fetch(jobListLink,{
    //       method:'GET'
    //   }).then(res=>res.json()).then(result=>{
    //       let jobs = [];
    //       result.forEach(parent=>{
    //           parent.field_one.forEach(stream=>{
    //               let ctgsSorted = stream.field_two.sort((a,b)=>a.name.localeCompare(b.name));
    //               ctgsSorted.forEach(ctgs=>{
    //                   let subjobs =[];
    //                   ctgs.field_three.forEach(job=>{
    //                       subjobs.push({__id:job.id,name:job.name,catgeory:ctgs.id,catgeory_name:ctgs.name,stream:stream.id,parent:parent.id})
    //                   })
    //                   jobs.push({id:ctgs.id,name:ctgs.name,subjobs:subjobs,description:ctgs.description});

    //               });

    //           })
    //       })
    //       this.jobsData= jobs.sort((a,b)=>a.name.localeCompare(b.name));
    //     });
    //   },
    moment: function () {
      return moment();
    },
    cutText: function (text, characters) {
      if (!text) return;
      return text.length > characters
        ? text.substring(0, characters) + '...'
        : text;
    },
    htmlToText(html) {
      let div = document.createElement('div');
      div.style.display = 'none';
      div.innerHTML = html;
      return div.innerText;
    },
  },
};
</script>